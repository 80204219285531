<script>
import UIImage from '@components/UIImage';

export default {
	name: 'LoginRecoverSuccess',
	components: {
		UIImage
	},
	data() {
		return {
			notification: false
		}
	},
	methods: {
		goto() {
			this.$router.push({ name: 'login.home' });
		},
	}
}
</script>


<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Agora você já pode efetuar seu login usando os novos dados
			</h2>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<div class="columns is-multiline">
					<div class="column is-12 has-text-centered mt-0">
						<UIImage
							class="success-img"
							size="1:1"
							bgsize="cover"
							:src="'recover/success.svg'"
						/>
					</div>
					<div class="column is-12 has-text-centered mt-0">
						<button
							class="button is-primary is-rounded is-outlined"
							@click="goto"
						>
							Voltar para o login
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.success-img {
	max-width: 75%;
	height: auto;
}
</style>
